//COLORS
$primary: #5994ad;
$secondary: #3f987b;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
$light: #fbfbfb;
$lighter: #ccc;
$gray-900: #14191c;
// $dark:          $gray-800 !default;

$grid-gutter-width: 4rem;

$navbar-padding-x: $grid-gutter-width/2;
