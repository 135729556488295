// @import url("https://fonts.googleapis.com/css?family=Saira+Extra+Condensed:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Saira+Extra+Condensed&display=swap");
.App {
	padding-top: 75px;
	position: relative;
}
body {
	font-family: "Lato", sans-serif;
	font-weight: 300;
}
a {
	font-family: "Saira Extra Condensed", sans-serif;
	&:hover {
		text-decoration: none;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

.nav-link {
	font-size: 1.25rem;
	padding: 0.5rem 1rem !important;
	@extend .text-uppercase;
}
.cp-nav {
	background-color: #fff;
	background-repeat: repeat-x;
	background-image: url("/img/header_pattern.png");
	box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
	@extend .navbar;
	@extend .navbar-expand-xl;
	@extend .navbar-light;
	@extend .pt-3;
	@extend .fixed-top;
	.navbar-brand {
		img {
			height: 42px;
		}
	}
	display: block;
}
.cp-footer {
	background-repeat: repeat-x;
	background-image: url("/img/footer_pattern.png");
	padding: 1.5rem 1rem 1rem;
	@extend .d-flex;
	@extend .justify-content-center;
	@extend .align-items-center;
	img.cp_symbol {
		height: 5rem;
	}
}
#introduction,
#goals,
#pulseprotocol,
#team,
#roadmap,
#conclusion,
#contact {
	// @extend .container-fluid;
	width: 100%;
	padding: 90px 0;
}
#contact {
	.rounded-pill {
		border-radius: 1.5rem !important;
	}
}
#conclusion {
	height: 800px;
	background: url("/img/footer_pic.jpg") top/cover no-repeat;
}

//SLick Slider
.slick-slider {
	width: 100%;
}
.slick-track,
.slick-list {
	display: flex !important;
}
.slick-slide {
	display: flex;
	height: 100% !important;
	align-self: stretch;
	& > div {
		display: flex;
		height: 100% !important;
		align-self: stretch;
	}
}
.cp-slick {
	&-title,
	&-subtitle {
		padding-bottom: 3rem;
	}
	&-img {
		max-width: 100%;
		max-height: 100%;
	}
	&-btn {
		@extend .btn;
		@extend .btn-lg;
		@extend .btn-secondary;
		@extend .rounded-pill;
		@extend .px-md-5;
		@extend .px-4;
		@extend .text-uppercase;
		margin-left: 10rem;
		margin-right: auto;
		&-prev,
		&-next {
			background: #fff;
			outline: none;
			position: absolute;
			border: 1px solid $lighter;
			border-radius: 1.5rem;
			top: auto !important;
			bottom: 0;
			transform: none !important;
			padding: 1rem !important;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: border 0.3s;
			svg {
				width: 1rem;
				height: 1rem;
				path {
					// fill: $lighter;
					transition: fill 0.2s linear;
				}
			}
			&:hover,
			&:focus {
				outline: none;
				border: 1px solid $secondary;
				// transform: translateY(-50%) scale(1) !important;
				svg {
					path {
						// fill: $secondary;
					}
				}
			}
		}
		&-prev {
			left: 0 !important;
		}
		&-next {
			right: auto !important;
			left: 5rem;
			svg {
				transform: rotate(180deg);
			}
		}
	}
}

.card {
	overflow: hidden;
	transition: transform 1s;
	&:hover {
		transform: translate(+2px, +2px);
	}
	&-social_links {
		path {
			transition: fill 0.2s;
		}
		svg:hover {
			path {
				fill: $secondary;
			}
		}
	}
}

// Grid
#team {
	[class*="col-"] {
		margin-bottom: 2rem !important;
	}
}

//Responsive

@include media-breakpoint-down(sm) {
	.container-fluid,
	.container,
	[class*="col-"] {
		padding: 0 1rem !important;
	}
	.row {
		margin: 0 -1rem !important;
	}

	.cp-nav {
		padding: 0.5rem 1rem;
		.navbar-brand {
			img {
				height: 32px;
			}
		}
	}
	.cp-slick {
		&-title {
			font-size: 1.7rem;
			padding-bottom: 1.5rem;
		}
		&-subtitle {
			padding-bottom: 1.5rem;
		}
		&-btn {
			margin-left: auto;
			&-prev,
			&-next {
				position: static;
				margin: 1rem 0.5rem;
			}
		}
		&-nav {
			display: flex;
			justify-content: center;
			width: 100%;
		}
	}
	#introduction,
	#goals,
	#pulseprotocol,
	#team,
	#roadmap,
	#conclusion,
	#contact {
		padding: 60px 0;
	}
}

// Slider override
// .slider {
// 	height: 500px !important;
// 	a.previousButton,
// 	a.nextButton {
// 		border: 1px solid $lighter;
// 		border-radius: 1.5rem;
// 		top: auto !important;
// 		bottom: 0;
// 		transform: none !important;
// 		padding: 1rem !important;
// 		svg {
// 			width: 1rem;
// 			height: 1rem;
// 			polygon {
// 				transition: fill 0.3s linear;
// 			}
// 		}
// 		&:hover {
// 			border: 1px solid $secondary;
// 			// transform: translateY(-50%) scale(1) !important;
// 			svg {
// 				polygon {
// 					fill: $secondary;
// 				}
// 			}
// 		}
// 	}
// 	a.previousButton {
// 		left: 0 !important;
// 	}
// 	a.nextButton {
// 		right: auto !important;
// 		left: 5rem;
// 	}
// 	//content
// 	.cp-carousel {
// 		&-btn {
// 			@extend .btn;
// 			@extend .btn-lg;
// 			@extend .btn-secondary;
// 			@extend .rounded-pill;
// 			@extend .px-5;
// 			@extend .text-uppercase;
// 			margin-left: 10rem;
// 			margin-right: auto;
// 		}
// 		&-img {
// 			max-width: 100%;
// 			max-height: 100%;
// 		}
// 	}
// }
